import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Menu",
  "type": "Navigation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Menu`}</strong>{` is used in a combination with `}<strong parentName="p">{`Menu Item`}</strong>{` and composes a menu UI element, that
is typically used inside dropdowns and select.`}</p>
    <hr></hr>
    <h2>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=false center=true background=layout",
        "live": "true",
        "editor": "false",
        "center": "true",
        "background": "layout"
      }}>{`<Menu>
  <MenuItem>Invoices</MenuItem>
  <MenuItem>Services</MenuItem>
  <MenuItem>Partners</MenuItem>
</Menu>
`}</code></pre>
    <h2>{`Menu Item`}</h2>
    <p><strong parentName="p">{`MenuItem`}</strong>{` is additional component used for the content of the `}<strong parentName="p">{`Menu`}</strong>{` component, it
passes its props to the underlying component, which by default is a `}<inlineCode parentName="p">{`button`}</inlineCode>{` element.`}</p>
    <h2>{`Icons`}</h2>
    <p><strong parentName="p">{`MenuItem`}</strong>{` can have an icon on the left and on the right side.`}</p>
    <h3>{`Left Icon`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=false center=true background=layout",
        "live": "true",
        "editor": "false",
        "center": "true",
        "background": "layout"
      }}>{`<Menu>
  <MenuItem leftIcon={ <Icons.BiCog /> }>Settings</MenuItem>
  <MenuItem leftIcon={ <Icons.BiLogOut /> }>Sign Out</MenuItem>
</Menu>
`}</code></pre>
    <h3>{`Right Icon`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=false center=true background=layout",
        "live": "true",
        "editor": "false",
        "center": "true",
        "background": "layout"
      }}>{`<Menu>
  <MenuItem rightIcon={ <Icons.BiCog /> }>Settings</MenuItem>
  <MenuItem rightIcon={ <Icons.BiLogOut /> }>Sign Out</MenuItem>
</Menu>
`}</code></pre>
    <h2>{`Props of `}<inlineCode parentName="h2">{`Menu`}</inlineCode></h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The content of the menu`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Props of `}<inlineCode parentName="h2">{`MenuItem`}</inlineCode></h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The content of the menu`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`component`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`React.ElementType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`button`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The underlying root node`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leftIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Icon displayed on the left`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`rightIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Icon displayed on the right`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      